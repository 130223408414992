<template>
    <div id="about">

		<LightBox
			v-bind:project="selected"
			v-if="modal"
			v-on:close="modal=false"
		/>

        <h1>About me</h1>

        <h2>Experience</h2>
        <p>
            More than 15 years of experience in software development and software architecture, also experience in project management and leading small teams. Further experiences in agile process management as a scrum master. Currently employed as a software engineer at
            <a
                href="http://www.iart.ch"
                target="_blank"
            >iart ag</a> in Münchenstein, Switzerland.
        </p>

        <br />

        <h2>Skills</h2>
        <h4>General</h4>
        <p>software engineering, software architecture, devops, agile development, scrum, kanban, process management, project management, conceptual design, experience in leading small teams</p>
        <h4>Programming</h4>
        <p>javascript, vue, node-red, node.js, python, flex, air, actionscript 3, away3d, papervision3d, wiiflash, away physics, box2d, phidgets, arduino, open media controller, android, augmented reality</p>
        <h4>Languages</h4>
        <p>german (mother tongue), english (fluently), french (basic), italian (very basic)</p>

        <br />

        <h2>Awards</h2>

        <div class="award">
            <div class="awardImg">
                <a
                    href="http://www.goldenawardmontreux.com/winners2021/finalists.php?entrytype=SPATIAL%20COMMUNICATION"
                    target="_blank"
                >
                    <img
                        src="@/assets/imgs/awards/montreux.jpg"
                        width="112"
                        height="80"
                        alt="Golden Award of Montreux 2021"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="http://www.goldenawardmontreux.com/winners2021/finalists.php?entrytype=SPATIAL%20COMMUNICATION"
                        target="_blank"
                    >Golden Award of Montreux 2021 - Winner</a>
                </h4>
                <b>Category: </b>Spatial Communication: Permanent Brand Communication
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(25)">Schindler City Center</span>, won by
                <a
                    href="https://iart.ch/work/schindler-city-center"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software development of a media control system
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="https://www.adc.de/content/uploads/2021/05/ADC_Wettbewerb_2021_Gewinnerliste-1.pdf"
                    target="_blank"
                >
                    <img
                        src="@/assets/imgs/awards/adc.jpg"
                        width="112"
                        height="80"
                        alt="ADC Award 2021"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="https://www.adc.de/content/uploads/2021/05/ADC_Wettbewerb_2021_Gewinnerliste-1.pdf"
                        target="_blank"
                    >ADC Award 2021 - Auszeichnung</a>
                </h4>
                <b>Category: </b>Exhibition: Exhibition for Brand
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(25)">Schindler City Center</span>, won by
                <a
                    href="https://iart.ch/work/schindler-city-center"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software development of a media control system
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="https://www.german-design-award.com/en/the-winners/gallery/detail/29124-arabian-journeys.html"
                    target="_blank"
                >
                    <img
                        src="@/assets/imgs/awards/german-design-award.jpg"
                        width="112"
                        height="80"
                        alt="German Design Award 2020"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="https://www.german-design-award.com/en/the-winners/gallery/detail/29124-arabian-journeys.html"
                        target="_blank"
                    >German Design Award 2020 - Winner</a>
                </h4>
                <b>Category: </b>Excellent Architecture: Fair and Exhibition
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(22)">Arabian Journeys</span>, won by <a href="https://www.atelier-brueckner.com/en" target="_blank">Atelier Brückner</a>, production company:
                <a
                    href="https://iart.ch/work/arabian-journeys"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development of 3 installations
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="http://www.goldenawardmontreux.com/winners2019/finalists.php?entrytype=Spatial%20Communication"
                    target="_blank"
                >
                    <img
                        src="@/assets/imgs/awards/montreux.jpg"
                        width="112"
                        height="80"
                        alt="Golden Award of Montreux 2019"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="http://www.goldenawardmontreux.com/winners2019/finalists.php?entrytype=Spatial%20Communication"
                        target="_blank"
                    >Golden Award of Montreux 2019 - Finalist</a>
                </h4>
                <b>Category: </b>Spatial Communication: Permanent Thematic Exhibition
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(22)">Arabian Journeys</span>, won by <a href="https://www.atelier-brueckner.com/en" target="_blank">Atelier Brückner</a>, production company:
                <a
                    href="https://iart.ch/work/arabian-journeys"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development of 3 installations
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a href="https://www.iconic-world.com/directory/arabian-journeys" target="_blank">
                    <img
                        src="@/assets/imgs/awards/iconic_2019.jpg"
                        width="112"
                        height="80"
                        alt="Iconic Award 2019"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="https://www.iconic-world.com/directory/arabian-journeys"
                        target="_blank"
                    >Iconic Awards: Innovative Architecture 2019 - Winner</a>
                </h4>
                <b>Category: </b>Architecture
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(22)">Arabian Journeys</span>, won by <a href="https://www.atelier-brueckner.com/en" target="_blank">Atelier Brückner</a>, production company:
                <a
                    href="https://iart.ch/work/arabian-journeys"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development of 3 installations
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="https://www.adc.de/content/uploads/dlm_uploads/2019/05/Gewinnerliste_Wettbewerb_2019.pdf"
                    target="_blank"
                >
                    <img
                        src="@/assets/imgs/awards/adc.jpg"
                        width="112"
                        height="80"
                        alt="ADC Award 2019"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="https://www.adc.de/content/uploads/dlm_uploads/2019/05/Gewinnerliste_Wettbewerb_2019.pdf"
                        target="_blank"
                    >ADC Award 2019 - Bronze</a>
                </h4>
                <b>Category: </b>Exhibition Experience
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(22)">Arabian Journeys</span>, won by <a href="https://www.atelier-brueckner.com/en" target="_blank">Atelier Brückner</a>, production company:
                <a
                    href="https://iart.ch/work/arabian-journeys"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development of 3 installations
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a href="https://segd.org/interactive-books-paul-gauguin" target="_blank">
                    <img
                        src="@/assets/imgs/awards/segd-2016.jpg"
                        width="112"
                        height="80"
                        alt="SEGD Merit Award 2016"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="https://segd.org/interactive-books-paul-gauguin"
                        target="_blank"
                    >SEGD Merit Award 2016</a>
                </h4>
                <b>Category: </b>Interactive Experience
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(12)">Interactive Books on Paul Gauguin</span>, won by
                <a
                    href="http://iart.ch/en/-/paul-gauguin-in-der-fondation-beyeler-der-multimediale-vermittlungsraum-zur-ausstellung"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="http://ifworlddesignguide.com/search?q=iart#/pages/page/entry/171948-gauguin-interactive-book"
                    target="_blank"
                >
                    <img src="@/assets/imgs/awards/if-2016.jpg" alt="iF Design Award 2016" />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="http://ifworlddesignguide.com/search?q=iart#/pages/page/entry/171948-gauguin-interactive-book"
                        target="_blank"
                    >iF Design Award 2016</a>
                </h4>
                <b>Category: </b>Interior Architecture: Installation
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(12)">Interactive Books on Paul Gauguin</span>, won by
                <a
                    href="http://iart.ch/en/-/paul-gauguin-in-der-fondation-beyeler-der-multimediale-vermittlungsraum-zur-ausstellung"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a href="http://www.adc.ch/de/archiv/#/adc-award-2016/J/J1.16.256" target="_blank">
                    <img
                        src="@/assets/imgs/awards/adc-switzerland-2016.jpg"
                        alt="ADC Award Switzerland 2016"
                    />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="http://www.adc.ch/de/archiv/#/adc-award-2016/J/J1.16.256"
                        target="_blank"
                    >ADC Award Switzerland 2016 - Bronze</a>
                </h4>
                <b>Category: </b>Creative Media
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(12)">Interactive Books on Paul Gauguin</span>, won by
                <a
                    href="http://iart.ch/en/-/paul-gauguin-in-der-fondation-beyeler-der-multimediale-vermittlungsraum-zur-ausstellung"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="http://red-dot.de/cd/de/online-exhibition/work/?code=15-01414&amp;y=2015&amp;c=217&amp;a=0"
                    target="_blank"
                >
                    <img src="@/assets/imgs/awards/reddot-2015.jpg" alt="Red Dot Award 2015" />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="http://red-dot.de/cd/de/online-exhibition/work/?code=15-01414&amp;y=2015&amp;c=217&amp;a=0"
                        target="_blank"
                    >Red Dot Award 2015</a>
                </h4>
                <b>Category: </b>Communication Design
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(12)">Interactive Books on Paul Gauguin</span>, won by
                <a
                    href="http://iart.ch/en/-/paul-gauguin-in-der-fondation-beyeler-der-multimediale-vermittlungsraum-zur-ausstellung"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="http://essence.sgv.ch/de/auszeichnungen-2015/#%21/interaktive-buecher-zu-paul-gauguin"
                    target="_blank"
                >
                    <img src="@/assets/imgs/awards/essence-15.jpg" alt="Essence 15 award" />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="http://essence.sgv.ch/de/auszeichnungen-2015/#%21/interaktive-buecher-zu-paul-gauguin"
                        target="_blank"
                    >Essence 15 - Silver</a>
                </h4>
                <b>Category: </b>Interactive
                <br />
                <b>Project: </b><span class="awardLink" v-on:click="onAwardClicked(12)">Interactive Books on Paul Gauguin</span>, won by
                <a
                    href="http://iart.ch/en/-/paul-gauguin-in-der-fondation-beyeler-der-multimediale-vermittlungsraum-zur-ausstellung"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development
            </div>
        </div>

        <div class="award">
            <div class="awardImg">
                <a
                    href="http://essence.sgv.ch/de/auszeichnungen-2015/#%21/interaktive-buecher-zu-paul-gauguin"
                    target="_blank"
                >
                    <img src="@/assets/imgs/awards/essence-15.jpg" alt="Essence 15 award" />
                </a>
            </div>
            <div class="awardDescr">
                <h4>
                    <a
                        href="http://essence.sgv.ch/de/auszeichnungen-2015/#%21/interaktive-buecher-zu-paul-gauguin"
                        target="_blank"
                    >Essence 15</a>
                </h4>
                <b>Category: </b>Price of the members
                <br />
                <b>Project: </b>
                <span class="awardLink" v-on:click="onAwardClicked(12)">Interactive Books on Paul Gauguin</span>, won by
                <a
                    href="http://iart.ch/en/-/paul-gauguin-in-der-fondation-beyeler-der-multimediale-vermittlungsraum-zur-ausstellung"
                    target="_blank"
                >iart ag</a>
                <br />
                <b>Scope of work: </b>software architecture and software development
            </div>
        </div>

        <br />

        <h2>Short CV</h2>
        <p>
            2013 - now | Software engineer,
            <a href="https://iart.ch/" target="_blank">iart ag</a>, Münchenstein, Switzerland
        </p>
        <p>
            2010 - 2013 | Software developer,
            <a
                href="https://iart.ch/news/iart-und-tegoro-solutions-b%C3%BCndeln-kr%C3%A4fte-per-2013"
                target="_blank"
            >Tegoro Solutions ag</a>, Basel, Switzerland
        </p>
        <p>
            2007 - 2010 | Multimedia-developer and project manager,
            <a
                href="http://mediaville.de/"
                target="_blank"
            >Mediaville GmbH</a>, Weil am Rhein, Germany
        </p>
        <p>2003 - 2007 | Freelancer for programming and digital movie production, Berlin, Germany</p>
        <p>
            1999 - 2003 | Studies of media-informatics,
            <a
                href="https://www.beuth-hochschule.de/"
                target="_blank"
            >Beuth Hochschule für Technik Berlin</a>, Berlin, Germany
        </p>
        <p>
            1996 - 1999 | Photography assistant for fashion and advertising,
            <a
                href="https://theurer-fotografie.de/"
                target="_blank"
            >Studio Elmar Theurer & Elke Mann</a>, Karlsruhe, Germany
        </p>
        <p>
            1993 - 1996 | Studies of mathematics and informatics,
            <a
                href="https://www.kit.edu/index.php"
                target="_blank"
            >Karlsruhe Institute of Technology</a>, Karlsruhe, Germany
        </p>

        <br />
    </div>
</template>

<script>
import LightBox from '@/components/LightBox.vue'
import json from "@/assets/projects.json";

export default {
	name: "About",
	components: {
		LightBox
	},
    data() {
        return {
			projects: json,
			selected: null,
			modal: false
        };
    },
    methods: {
        onAwardClicked(projectId) {
			this.selected = this.getProjectById(projectId);
			this.modal = true;
			//this.$router.push({ path: "/projects" });
		},
		getProjectById(id) {
			let p;
			for (p in this.projects) {
				if(this.projects[p].id == id) {
					return this.projects[p]
				}
			}
		}
    }
};
</script>

<style scoped>
h4 {
    margin-top: 12px;
}

.award {
    display: flex;
    align-items: top;
    margin-top: 10px;
    margin-bottom: 22px;
}

.awardImg {
    margin-right: 20px;
    padding: 1px;
    border: 1px solid #999;
    background: white; /*none !important;*/
}

.awardImg a:hover {
    background: white;
}

.awardDescr {
    line-height: 1.5em;
}

.awardDescr h4 {
    margin-top: 0px;
}

.awardLink {
	color: #333;
    text-decoration: underline;
}
.awardLink:hover {
	color: #fff;
    text-decoration: none;
    background-color: #333;
	cursor: pointer;
}

</style>